<template>
    <div >
        <div class="search-nav">
            <div class="search-form">
                <el-form :inline="true" ref="listQuery" :model="listQuery">
                    <div class="form">
                        <div class="search-conditions">   
                            <el-form-item label="设备型号" prop="deviceType">
                                <el-input class="input-w290-h32" v-model="listQuery.deviceType" placeholder="请输入设备型号"></el-input>
                            </el-form-item>
                            <el-form-item label="设备类型名称" prop="typeName">
                                <el-input class="input-w290-h32" v-model="listQuery.typeName" placeholder="请输入设备类型名称"></el-input>
                            </el-form-item>
                        </div>
                        <div class="search-btns">
                            <el-form-item style="display:none">
                            </el-form-item>
                            <tem-btn  type="primary" value="查询" @click="getList('search')"></tem-btn>
                            <!-- <tem-btn  type="primary" value="查询" @click="screachDevice"></tem-btn> -->
                            <tem-btn  type="primary" value="添加" @click="addDevice"></tem-btn>
                        </div>
                    </div>
                </el-form>
            </div>
        </div>
        <div class="table-list">
            <Table :table="table" :total="listQuery.total" :page.sync="listQuery.pageIndex" :size.sync="listQuery.pageSize"
            :orderBy.sync="listQuery.orderBy" @pagination="getList"></Table>
        </div>
        <!-- 添加&修改弹出框 -->
        <el-dialog :title="titleMap[dialogTitle]" :visible.sync="dialogForm1Visible"  width="600px">
            <el-form :model="form" ref="form1">
                <el-form-item prop="deviceType" v-if="this.dialogTitle != 'updateData'" label="设备型号" :label-width="formLabelWidth">
                    <el-input v-model="form.deviceType" autocomplete="off"  class="input-w290-h32"></el-input>
                </el-form-item>
                <el-form-item prop="typeName" label="设备类型名称" :label-width="formLabelWidth">
                    <el-input v-model="form.typeName" autocomplete="off"  class="input-w290-h32"></el-input>
                </el-form-item>
                <el-form-item label="是否支持离线指令" :label-width="formLabelWidth" prop="offLineSupport">
                    <el-radio-group v-model="form.offLineSupport">
                        <el-radio :label="true" >是</el-radio>
                        <el-radio :label="false" >否</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <tem-btn type="info" value="取消" @click="resetForm('form1')"></tem-btn>
                <tem-btn type="primary" value="确定" @click="dialogForm1Submit('form1')"></tem-btn>
            </div>
        </el-dialog>
        <!-- 关联指令弹框 -->
        <el-dialog title="关联指令" :visible.sync="dialogForm2Visible"  width="600px">
            <el-table
                ref="multipleTable2"
                :data="tableData2"
                tooltip-effect="dark"
                style="width: 100%"
                height="250"
                @select="handleSelectionChange"
                @select-all="handleSelectionAll">
                <el-table-column
                    type="selection"
                    width="55">
                </el-table-column>
                <el-table-column
                prop="instructName"
                label="指令名称"
                width="120">
                </el-table-column>
                <el-table-column
                prop="instructContent"
                label="指令内容"
                show-overflow-tooltip>
                </el-table-column>
            </el-table>
            <!-- <el-pagination
                background
                @size-change="handleSizeChange2"
                @current-change="handleCurrentChange2"
                :current-page="currentPage2"
                :page-sizes="[5, 10,15, 20]"
                :page-size="pagesize2"
                layout="total, sizes, prev, pager, next, jumper"
                :total="dataTotal2"
                style="margin-top: 20px;text-align: center;">
            </el-pagination> -->
            <div class="box">
                <div v-for="i in multipleSelectionAll2" :key="i.instructName">
                    {{i.instructName}}
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <tem-btn type="primary" value="保存" @click="saveDeviceCmdSet"></tem-btn>
            </div>
        </el-dialog>
  </div>
</template>
<script>
    import Table from '@/components/table/table';
    import {addDevice,deleteDeviceType,updateDevice,getDeviceList,bindCmd,unbindCmd,isBindCmd,cmdListAll} from "@/api/device";
    export default {
        components: {
            Table
        },
        data() {
            return {
                containsLower: false,
                //搜索栏数据
                listQuery:{
                    typeName: '',
                    deviceType: '',
                    total:10000,//数据条数
                    includeSub:false,//是否包含下级
                    pageIndex: 1,//当前查询页面
                    pageSize: 10,//每页数据数量
                    orderBy:''
                },
                form1: {
                    deviceType: [
                        {required: true, message: '请输入编码', trigger: 'change'}
                    ],
                    typeName: [
                        {required: true, message: '请输入设备类型名称', trigger: 'change'}
                    ],
                    offLineSupport: [
                        {required: true, message: '是否支持离线指令', trigger: 'change'}
                    ],

                },
                //表格数据
                table: {
                    configItem: true, //是否开启表格项配置
                    data: [],
                    header: [
                        {
                            type: 'index',
                            label: '序号',
                            width: '55',
                            ifShow: true,
                        },
                        {
                        prop: 'id',
                        label: 'id',
                        ifShow: false,
                        },
                        // {
                        //   prop: 'bindInstructSet',
                        //   label: '绑定的指令id集合',
                        //   minWidth: '120',
                        //   ifShow: true,
                        // },
                        {
                            prop: 'typeName',
                            label: '设备类型名称',
                            minWidth: '120',
                            tooltip: true,
                            ifShow: true,
                        },
                        {
                        prop: 'deviceType',
                        label: '设备型号',
                        minWidth: '120',
                        ifShow: true,
                        },
                        {
                            prop: 'offLineSupport',
                            label: '是否支持离线指令',
                            tooltip: true,
                            ifShow: true,
                        },
                        {
                            prop: 'bindInstructSet',
                            label: '绑定的指令id集合',
                            ifShow: true,
                            render: (h, param) => {
                                return h('div', [
                                    h('span', {
                                    }, param.bindInstructSet.length == 0? '--': "["+param.bindInstructSet.toString()+"]"),
                                ]);
                            }
                        },
                        {
                            prop: 'options',
                            label: '操作',
                            minWidth: '180',
                            ifShow: true,
                            fixed: 'right',
                            render:(h,param) => {
                                return h('div', [
                                    h(
                                        'el-tooltip', {
                                            props: {
                                                effect:'dark',
                                                content:'编辑',
                                                placement:'top'
                                            }
                                        },
                                        [h('span',{
                                            style:{
                                                cursor:'pointer'
                                            },
                                            on:{
                                                click:()=> {
                                                    this.updataDevice(param);
                                                }
                                            }
                                        },[
                                            h('svg-icon',{
                                                props:{
                                                    iconClass:'icon-edit',
                                                    size: '16',
                                                    className:'icon'
                                                },
                                            })
                                        ])]
                                    ),
                                    h(
                                        'el-tooltip', {
                                            props: {
                                                effect:'dark',
                                                content:'删除',
                                                placement:'top',
                                                
                                            }
                                        },
                                        [h('span',{
                                            style:{
                                                cursor:'pointer',
                                                marginLeft: '20px'
                                            },
                                            on:{
                                                click:()=> {
                                                     this.deleteDevice(param);
                                                }
                                            }
                                        },[
                                            h('svg-icon',{
                                                props:{
                                                    iconClass:'icon-del',
                                                    size: '16',
                                                    className:'icon'
                                                }
                                            })
                                        ])]
                                    ),
                                    h('el-button', {
                                        props: {
                                            size: 'small'
                                        },
                                        style:{
                                            marginLeft: '20px'
                                        },
                                        on: {
                                            click: () => {
                                                this.getDeviceCmdList(param);
                                            }
                                        }
                                    }, '关联指令')
                                ]);
                            }
                        }
                    ],
                },
                noSelectIds:[],
                deviceCmdList:[],
                titleMap:{
                    addData : "添加",
                    updateData : "修改"
                },
                dialogTitle:'',
                tableData: [],
                currentPage:1,
                pagesize:20,
                dataTotal:0,
                dialogForm1Visible: false,
                dialogForm2Visible: false,
                form: {
                    id:-1,
                    deviceType:'',
                    typeName: '',
                    offLineSupport:''
                },
                form1Selection: [],
                form2: {
                    name: ''
                },
                //所有指令列表
                tableData2: [{
                        id: '1',
                        type:'工作模式',
                        name: '省电模式',
                        content: 'MODE,2,{0},{1}#'
                    },{
                        id: '2',
                        type:'SOS号码',
                        name: '追踪模式',
                        content: 'MODE,1,{0},{1}#'
                    },{
                        id: '3',
                        type:'工作模式',
                        name: '库存模式',
                        content: 'MODE,5,10:00,{0}#'
                    },{
                        id: '4',
                        type:'SOS号码',
                        name: '工作模式',
                        content: '111'
                }],
                currentPage2:1,
                pagesize2:5,
                dataTotal2:0,
                multipleSelectionAll: [],   // 所有选中的数据包含跨页数据
                multipleSelectionAll2: [],   // 所有选中的数据包含跨页数据
                multipleSelection: [],   // 当前页选中的数据
        　　    idKey: 'id', // 标识列表数据中每一行的唯一键的名称(需要按自己的数据改一下)
                rowInfor:{},
                deviceType:'',
                formLabelWidth: '150px'
            }
        },
        watch: {
        // dialogForm2Visible: function (newData, oldData) {
        //   if(!newData){
        //     this.$refs.multipleTable2.clearSelection();
        //   }
        // }
        },
        created(){
            this.getList();
        // this.getList2()
        },
        methods: {
            addDevice(){
                //添加机型
                this.form = {
                    deviceType:'',
                    typeName: '',
                    offLineSupport:''
                }
                this.dialogTitle = "addData";
                this.dialogForm1Visible = true
            },
            updataDevice(row){
                //编辑机型
                this.form = {
                    id:row.id,
                    typeName:row.typeName,
                    deviceType: row.deviceType,
                    offLineSupport:row.offLineSupport
                }
                // console.log(this.form)
                this.dialogTitle = "updateData";
                this.dialogForm1Visible = true;
            },
            deleteDevice(row){
                var  _ = this;
                //删除机型
                deleteDeviceType({
                    "id":row.id
                }).then(res => {
                    // console.log(res);
                    this.getList();
                })
            },
            getList(val){
                if(val == "search"){
                    this.listQuery.pageIndex =1 ;
                }
                getDeviceList(this.listQuery).then(res => {
                    // console.log(res)
                    this.table.data = res.data;
                    this.listQuery.total = res.count; 
                })
            },
            handleSizeChange(size) {
                this.pagesize = size;
                this.getList();
                // console.log(`每页 ${size} 条`);
            },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.getList();
                // console.log(`当前页: ${val}`);
            },
            resetForm(formName) { 
                this.$refs[formName].resetFields();
                if(formName == 'form1'){
                this.dialogForm1Visible = false
                }else{
                this.dialogForm2Visible = false;
                }
            },
            dialogForm1Submit(formName){
                var _ = this;
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (this.dialogTitle == "addData") {
                            //添加
                            addDevice({
                                "typeName":_.form.typeName,
                                "deviceType":_.form.deviceType,
                                "offLineSupport":_.form.offLineSupport
                            }).then(res => {
                                // console.log(res);
                                _.getList();
                                _.dialogForm1Visible = false;
                            })
                        }else{
                            //修改
                            addDevice(_.form).then(res => {
                                // console.log(res);
                                _.getList();
                                _.dialogForm1Visible = false;
                            })
                        }
                    }
                });
                
                
            },
            getDeviceCmdList(row){
                var that = this;
                this.dialogForm2Visible = true  
                // this.deviceType = row.deviceType;
                
                //获取已绑定的cmd列表
                // isBindCmd(row.instructName).then(res => {
                    // var data = res.data.data;
                    //设置选中方法;
                    // if(!data || data.length <= 0){
                    //   return;
                    // }
                    var _ =  this,
                    idKey = _.idKey;
                    _.rowInfor = row;
                    _.deviceCmdList = [];
                    //获取当前点击的设备 绑定的所有指令列表
                    row.bindInstructSet.forEach(res=>{
                        _.deviceCmdList.push(res)
                    })
                // }) 
                this.noSelectIds = [];
                this.getList2()
            },
            getList2(){
                // console.log("getList2")
                //获取所有指令
                cmdListAll({}).then(res => {
                    // console.log(res)
                    this.tableData2 = res.data;
                    this.dataTotal2 = res.data.length
                    
                    setTimeout(()=>{
                        this.setSelectRow();
                    }, 10)
                })
            },
            handleSizeChange2(size) {
                console.log("handleSizeChange2")
                this.changePageCoreRecordData();
                this.pagesize2 = size;
                this.getList2();
                
                // console.log(`每页 ${size} 条`);
            },
            handleCurrentChange2(val) {
                console.log("handleCurrentChange2")
                this.changePageCoreRecordData();
                this.currentPage2 = val;
                this.getList2();
                
                // console.log(`当前页: ${val}`);
            },
            handleSelectionChange(val,row){
                this.multipleSelection = val;
                // true就是选中，0或者false是取消选中
                let selected = val.length && val.indexOf(row) !== -1;
                let idKey = this.idKey;
                let _ = this; 
                if(selected){
                    // _.rowInfor.bindInstructSet.push(row.id)
                    // _.deviceCmdList.push(row.id)
                    //绑定
                    // addDevice(_.rowInfor).then(res => {
                        _.multipleSelectionAll2.push(row)
                    // })
                }else{
                    // _.rowInfor.bindInstructSet.splice(_.rowInfor.bindInstructSet.findIndex(item => item === row.id), 1)
                    // addDevice(_.rowInfor).then(res => {
                        _.multipleSelectionAll2.splice(_.multipleSelectionAll2.findIndex(item => item.id === row.id), 1)
                    // })
                }
            },
            handleSelectionAll(rows){
                //当前选择的值的id
                var selectedNow = [];
                //var selectedNow = this.multipleSelectionAll2;
                // var selectedNow = JSON.parse(JSON.stringify(this.multipleSelectionAll2));
                this.multipleSelectionAll2.forEach(res => {
                    selectedNow.push(res.cmdId)
                })
                if(rows.length > 0){
                    //全选
                    rows.forEach(res => {
                        if(this.multipleSelectionAll2.findIndex(item => item.id === res.id) == -1){
                            this.rowInfor.bindInstructSet.push(res.id)
                        }
                    })
                    
                }else{
                    //全不选; 解除绑定
                    this.rowInfor.bindInstructSet = []
                }
                //绑定
                addDevice(this.rowInfor).then(res => {
                    this.multipleSelectionAll2 = JSON.parse(JSON.stringify(rows));
                })
            },
            saveDeviceCmdSet(){
                // console.log(this.multipleSelectionAll2)
                // this.multipleSelectionAll2.forEach(row=>{
                //   bindCmd({
                //     "cmdId":row[this.idKey],
                //     "deviceType":this.deviceType}).then(res => {
                //     console.log(res)
                //   })
                // })
                let _ = this;
                var ss = JSON.parse(JSON.stringify(_.multipleSelectionAll2))
                _.rowInfor.bindInstructSet = [];
                ss.forEach( v => {
                    _.rowInfor.bindInstructSet.push(v.id)
                })
                addDevice(_.rowInfor).then(res => {
                    // _.multipleSelectionAll2.push(row)
                })
                this.dialogForm2Visible = false;
                this.$refs.multipleTable2.clearSelection();
            },
            setSelectRow(){
                // console.log("setSelectRow")
                let idKey = this.idKey;
                let selectAllIds = [];
                let _ = this;
                _.multipleSelectionAll2 = [];
                for(var i = 0;i < this.tableData2.length;i++){
                    if(_.deviceCmdList.indexOf(_.tableData2[i][idKey]) >= 0){
                        _.$refs.multipleTable2.toggleRowSelection(this.tableData2[i],true);
                        _.multipleSelectionAll2.push(this.tableData2[i])
                    }
                }
                // console.log(_.multipleSelectionAll2)
            },
            changePageCoreRecordData(){
                console.log("changePageCoreRecordData")
                //标识当前行的唯一键的名称
                let idKey = this.idKey;
                let that = this;
                
                //总选择里面的key集合
                let selectAllIds = [];
                this.multipleSelection.forEach(row=>{
                selectAllIds.push(row[idKey]);
                })
                
                // let selectIds = []
                // 获取当前页选中的id
                // this.multipleSelection.forEach(row=>{
                //     selectIds.push(row[idKey]);
                //     // 如果总选择里面不包含当前页选中的数据，那么就加入到总选择集合里
                //     if (selectAllIds.indexOf(row[idKey]) < 0) {
                //         that.multipleSelectionAll.push(row);
                //     }
                // })

                let noSelectIds = [];
                // 得到当前页没有选中的id
                this.tableData2.forEach(row=>{
                    if (selectAllIds.indexOf(row[idKey]) < 0) {
                        noSelectIds.push(row[idKey]);
                    }
                })
                this.noSelectIds.forEach(id=>{
                    if (selectAllIds.indexOf(id) >= 0) {
                        for(let i = 0; i< that.multipleSelectionAll.length; i ++) {
                            if (that.multipleSelectionAll[i][idKey] == id) {
                                // 如果总选择中有未被选中的，那么就删除这条
                                that.multipleSelectionAll.splice(i, 1);
                                break;
                            }
                        }
                    }
                })
            }
        }
    }
</script>
<style lang="scss" scoped>
.box{
  overflow: auto;
  max-height: 100px;
  margin-top: 20px;
}
.box div{
  color: #fff;
  float: left;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 2px 4px;
  font-size: 12px;
  border-radius: 2px;
  background-color: #409EFF;
}

</style>
