import request from '@/utils/request'

//删除设备型号
export function deleteDeviceType(data){
    return request({
        url:'/instructConfig/deleteDeviceType',
        method:'get',
        params:data
    })
}
//新增设备型号
export function addDevice(data){
    return request({
        url:'/instructConfig/saveDeviceType',
        method:'post',
        data:data
    })
}
//设备型号修改
export function updateDevice(data){
    return request({
        url:'/config/deviceType/update',
        method:'post',
        data:data
    })
}

//分页查询设备类型
export function getDeviceList(data){
    return request({
        url:'/instructConfig/pageDeviceType',
        // url:'/config/deviceType/typeList',
        method:'get',
        params:data
    })
}

//设备类型绑定指令
export function bindCmd(data){
    return request({
        url:'/config/deviceType/bind',
        method:'post',
        data:data
    })
}

//设备类型解绑指令
export function unbindCmd(data){
    return request({
        url:'/config/deviceType/unbind',
        method:'post',
        data:data
    })
}

//设备类型解绑指令
export function isBindCmd(data){
    return request({
        url:'/config/deviceType/isBind/'+data,
        method:'post',
        data:data
    })
}

//指令列表
export function cmdListAll(data){
    return request({
        url:'/instructConfig/selectInstruct',
        // url:'/config/cmd/list',
        method:'get',
        params:data
    })
}